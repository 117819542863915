import { router } from "@inertiajs/react";
import { PictureAsPdfOutlined } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import ZoomButton from "app/frontend/components/buttons/ZoomButton";
import { Fragment, PropsWithChildren, useCallback, useMemo } from "react";

const LinkButton: React.FC<
    {
        button?: ButtonProps;
        href: string;
        hideIcons?: boolean;
        onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    } & PropsWithChildren
> = ({ children, href, button, hideIcons, onClick }) => {
    const isExternalLink = useMemo(() => !href.startsWith("/"), [href]);
    const isPDF = useMemo(() => href.endsWith(".pdf"), [href]);

    const handleNavigate = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();

            onClick?.(e);

            router.visit(href);
        },
        [href, onClick],
    );

    // https://www.a11yproject.com/checklist/#identify-links-that-open-in-a-new-tab-or-window
    // https://www.w3.org/WAI/WCAG22/Techniques/general/G201
    // https://www.w3.org/WAI/WCAG22/working-examples/css-new-window-warn/
    const icons = useMemo(() => {
        const _icons = [];

        if (!hideIcons && typeof children === "string") {
            if (isPDF) {
                _icons.push(
                    <Fragment key={`pdf-${href}`}>
                        &nbsp;
                        <PictureAsPdfOutlined
                            className="pdf-icon"
                            // aria-valuetext="PDF"
                            // aria-hidden={true}
                            titleAccess="PDF"
                            // title="PDF"
                            sx={{
                                fontSize: "1.5em",
                                fontWeight: "bolder",
                                verticalAlign: "middle",
                            }}
                        >
                            <use href="#pdf-icon" />
                            <figcaption>PDF</figcaption>
                        </PictureAsPdfOutlined>
                    </Fragment>,
                );
            }
            // if (isExternalLink) {
            //     _icons.push(
            //         <Fragment key={`external-${href}`}>
            //             &nbsp;
            //             <Launch
            //                 className="new-window-icon"
            //                 // aria-valuetext="External Link"
            //                 // aria-hidden={true}
            //                 titleAccess="External Link - Opens New Tab/Window"
            //                 // title="External Link - Opens New Tab/Window"
            //                 sx={{
            //                     fontSize: "1.5em",
            //                     fontWeight: "bolder",
            //                     verticalAlign: "middle",
            //                 }}
            //             >
            //                 <use href="#new-window-icon" />
            //                 <figcaption>External Link - Opens New Tab/Window</figcaption>
            //             </Launch>
            //         </Fragment>,
            //     );
            // }
        }
        return _icons;
    }, [isPDF, href, children, hideIcons]);

    const Component = button?.variant === "contained" ? ZoomButton : Button;

    return (
        <>
            <Component // NOSONAR
                {...button}
                sx={{
                    padding: 0,
                    boxShadow: 0,
                    ...button?.sx,
                }}
                style={{ fontWeight: "bolder" }}
                size={button?.size}
                onClick={isExternalLink ? onClick : handleNavigate}
                role="link"
                href={href}
                rel="noopener noreferrer"
                // @ts-expect-error - Property 'target' does not exist on type 'IntrinsicAttributes & ButtonOwnProps & Omit<ButtonBaseOwnProps, "classes"> & CommonProps & Omit<...>'.
                target={isExternalLink ? "_blank" : "_self"}
            >
                {children}
                {icons}
            </Component>
        </>
    );
};

export default LinkButton;
