import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AdminHeader from "app/frontend/components/static/admin/AdminHeader";
import dayjs from "dayjs";
import React, { PropsWithChildren } from "react";

import { ROUTES } from "app/frontend/constants/routes";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const PAGES: powerready.layout.IAdminHeaderPages = {
    ADMIN: [
        {
            name: <span>Banner Events</span>,
            routes: {
                main: ROUTES.Admin.Banners,
                aliases: [],
            },
        },
        {
            name: <span>Incentives Data</span>,
            routes: {
                main: ROUTES.Admin.Incentives,
                aliases: [ROUTES.Admin.IncentiveTiers],
            },
        },
        {
            name: <span>Contractors</span>,
            routes: {
                main: ROUTES.Admin.ContractorEdit(1),
                aliases: [],
            },
        },
        {
            name: <span>Settings</span>,
            routes: {
                main: ROUTES.Admin.Admins,
                aliases: [],
            },
        },
        {
            name: <span>Contact Submissions</span>,
            routes: {
                main: ROUTES.Admin.ContactSubmissions.INDEX,
                aliases: [],
            },
        },
    ],
    BD: [],
};

const AdminLayout: React.FC<PropsWithChildren & Record<string, any>> = ({ children, header_type, ...props }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AdminHeader pages={PAGES} headerType={header_type} role={props.current_admin_role} />

            {React.Children.map(children, (child) =>
                React.isValidElement(child)
                    ? React.cloneElement(child, {
                          ...(child?.props ?? {}),
                          ...props,
                      })
                    : child,
            )}
        </LocalizationProvider>
    );
};

const Layout = (page: React.JSX.Element) => <AdminLayout {...page.props}>{page}</AdminLayout>;

export default Layout;
