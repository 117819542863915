import { Box } from "@mui/material";
import React, { PropsWithChildren } from "react";

import ContentFooter from "app/frontend/components/static/ContentFooter";
import Header from "app/frontend/components/static/Header";
import PageFooter from "app/frontend/components/static/PageFooter";

const BaseLayout: React.FC<PropsWithChildren & Record<string, any>> = ({ children, ...props }) =>
    React.Children.map(children, (child) => {
        return (
            <main>
                <Header />

                {React.isValidElement(child)
                    ? React.cloneElement(child, {
                          ...(child?.props ?? {}),
                          ...props,
                      })
                    : child}

                <Box sx={{ flexGrow: 1, paddingTop: { sm: 2 }, paddingX: { sm: 2 } }} className="layout-box">
                    <ContentFooter />
                </Box>

                <PageFooter />
            </main>
        );
    });

const Layout = (page: React.JSX.Element) => <BaseLayout {...page.props}>{page}</BaseLayout>;

export default Layout;
